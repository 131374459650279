
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { VuetifyMixin, BAIconsMixin, AppHostnameMixin, AthleteProfileMixin, AuthMixin, MyCoachMixin, CoachRoutingMixin, StringsMixin, DebounceMixin, CoachObservationsMixin, CoachAthleteReportsMixin } from "@/mixins";
import Page from "@/views/Page.vue";
import * as Routes from "@/../types/constants/web_client_user.routes";
import { notificationStore } from '@/store';

import SharingSummaryCard from "@/components/ui/SharingSummaryCard.vue"
import RecruitingProfileCard from "@/components/profile/recruiting/RecruitingProfileCard.vue";
import IdCrossSvg from "@/components/svg/IdCrossSvg.vue";
import IdCrossSvgFail from "@/components/svg/IdCrossSvgFail.vue";
import SharingInfoProvider from "@/components/hoc/SharingInfoProvider.vue";
import SettingsAlertCard from "@/components/ui/SettingsAlertCard.vue";

import { RecruitingResponse } from '@/../types/enums/recruiting-response';
import { coachRecruitingSharingStore } from '@/store'
import { TargetedSharingModel } from '@/models/targetedSharing/TargetedSharingModel';
import SharingResponseBtn from "@/components/sharing/SharingResponseBtn.vue";
import SharingResponseNote from "@/components/sharing/SharingResponseNote.vue";

@Component({
	components: {
		Page,
		RecruitingProfileCard,		
		IdCrossSvg,
		IdCrossSvgFail,
		SharingInfoProvider,
		SettingsAlertCard,
		SharingSummaryCard,
		SharingResponseBtn,
		SharingResponseNote,
	},
	
})
export default class AthleteRecruitingProfileView extends Mixins(
	AuthMixin,
	AppHostnameMixin,
	AthleteProfileMixin,
	VuetifyMixin,
	MyCoachMixin,
	CoachRoutingMixin,
	StringsMixin,
	DebounceMixin,
	CoachObservationsMixin,
	CoachAthleteReportsMixin,
	BAIconsMixin,
) {
	@Prop({ type: String, default: undefined }) sharingUrlId;

	isCoachInfo: boolean = false;
	
	async mounted() {
		if( this.CoachAppActive ) await this.loadCoachAthleteView();
		if( this.HasNewCoachShare ) this.isCoachInfo = true;
	}

	RecruitingResponse = RecruitingResponse;
    get ObservationsAthleteId(): string {
        return this.athleteId;
    }
	get AthleteReportsAthleteId(): string {
		return this.athleteId;
	}

	showGameObservations: boolean = true;
	showAthleteReports: boolean = true;
	coachNotes: string = '';
	async loadCoachAthleteView() {
		await this.loadCoachShare();
		if( !this.coachShare ) {
			// if athlete has not shared their profile, add a new sharing record tagged with 'coach found the athlete'
			this.coachShare = await coachRecruitingSharingStore.addCoachView({athleteId: this.athleteId, coachId: this.MyCoachId});
			await this.loadCoachShare();
		}
		// indicate that the coach has seen the athlete
		await coachRecruitingSharingStore.setResponse({coachId: this.MyCoachId, id: this.coachShare.id, response: this.coachShare.Response? this.coachShare.Response : RecruitingResponse.Seen});
		this.coachNotes = this.coachShare.coachNotes;
	}
	coachShare: TargetedSharingModel | null = null
	get HasCoachShare(): boolean {
		return !this.IsEmpty(this.coachShare);
	}
	get HasNewCoachShare(): boolean {
		if( !this.HasCoachShare ) return false;
		return !this.coachShare.seen;
	}
	get CoachShareColor(): string {
		if( this.HasCoachShare ) return this.coachShare.Color('light');
		return 'transparent'
	}
	get CoachFoundAthlete(): boolean {
		if( this.HasCoachShare ) {
			// if the coach found the athlete but the athlete subsequently shared their profile, the state is incorrect
			// check the share message. if it is not empty, then the athlete has shared their profile
			// remove the 'found' status
			if( !!this.coachShare.message && this.coachShare.message.length > 0 ) this.coachShare.found = false;
		 	return this.coachShare.WasFound;
		}
		return true;
	}

	loadingCoachShare = true;
	async loadCoachShare() {
		this.loadingCoachShare = true;
		if( this.CoachAppActive ) {
			this.coachShare = await coachRecruitingSharingStore.findShareForAthleteId({coachId: this.MyCoachId, athleteId: this.athleteId});
		}
		this.loadingCoachShare = false;
	}
	async setResponse({ share, response } : {share: TargetedSharingModel, response: RecruitingResponse}) {
		try{
			await coachRecruitingSharingStore.setResponse({coachId: this.MyCoachId, id: share.id, response});
			this.loadCoachShare();
		} catch(e) {
			console.log(`Error in setResponse: ${e}`);
			notificationStore.pushSnackbarError({message: `Athlete update error: ${e}`});
		}
	}
	coachNotesInitialized: boolean = false;
	coachNotesChanged: boolean = false;
	coachNotesSaving: boolean = false;
	coachNotesSaved: boolean = false;
	async setCoachNotes({ share, notes } : {share: TargetedSharingModel, notes: string}) {
		this.coachNotesSaving = true;
		try{
			await coachRecruitingSharingStore.setCoachNotes({coachId: this.MyCoachId, id: share.id, notes});
			this.loadCoachShare();
		} catch(e) {
			console.log(`Error in setNotes: ${e}`);
			notificationStore.pushSnackbarError({message: `Athlete update error: ${e}`});
		}
		this.coachNotes = notes;
		this.coachNotesSaving = false;
		this.coachNotesChanged = false;
		this.coachNotesSaved = true;
		this.debounceCallback('completeCoachNotes', this.completeCoachNotes.bind(this), 5000);
	}
	onChangeCoachNotes() {
		this.coachNotesChanged = true;
	}
	@Watch('coachNotes')
	debounceCoachNotes() {
		if( !this.coachNotesInitialized ) {
			this.coachNotesInitialized = true;
			return;
		}
		this.coachNotesSaved = false;
		this.debounceCallback('updateCoachNotes', this.updateCoachNotes.bind(this), 2000);
	}
	async updateCoachNotes() {
		this.setCoachNotes( {share: this.coachShare, notes: this.coachNotes});
	}
	async completeCoachNotes() {
		this.coachNotesSaved = false;
	}

	get IsMyAthleteProfile(): boolean{
		return this.AthleteAppActive && !this.sharingUrlId;
	}

	get IsProfileEditable(): boolean {
		return this.AthleteAppActive || this.AdminAppActive || this.ClubAdminAppActive;
	}

	get CoachInfoIcon(): string {
		// coaching notes are visible
		if( this.isCoachInfo ) return this.BAIcons.collapse;

		// there is a new share
		if( this.HasNewCoachShare ) return this.BAIcons.expandHighlight;

		// no coach share exists, only coaching notes
		if( this.CoachFoundAthlete ) return this.BAIcons.expand;

		// there is a share that was previously viewed
		return this.BAIcons.expandHighlight;
	}
	get CoachInfoTooltip(): string {
		// coaching notes are visible
		if( this.isCoachInfo ) return 'Hide coaching notes';

		// there is a new share
		if( this.HasNewCoachShare ) return 'Show message from athlete';

		// no coach share exists, only coaching notes
		if( this.CoachFoundAthlete ) return 'View coaching notes';

		// there is a share that was previously viewed
		return 'Show athlete message and coach notes';
	}

	navigateToEdit(step?: string): void {
		// Open edit page
		this.$router.push({
			path: '/settings',
			params: {
				...this.$route.params,
				currentStep: step,
			},
			query: { u: this.CurrentUserProfileId }
		});
	}

	gotoComparative(step?: string): void {
		// Open edit page
		this.$router.push({
			path: '/settings',
			params: {
				...this.$route.params,
				currentStep: step,
			},
			query: { u: this.CurrentUserProfileId }
		});
	}

	gotoCoachDashboard(): void {
		this.$router.push({
			name: Routes.CoachDashboard
		})
	}
}
