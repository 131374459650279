
import { Component, Mixins } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { VuetifyMixin , VideoUploadMixin, MyAthleteMixin, FormRulesMixin, StringsMixin, BAIconsMixin} from "@/mixins";
import BAVideoStudio from "@/components/videoStudio/BAVideoStudio.vue";
import ClipPlayerStudio from "@/components/videoClipPlayer/ClipPlayerStudio.vue";
import AthleteVideoHighlightReel from '@/components/profile/athlete/AthleteVideoHighlightReel.vue';
import VideoClipLibrary from '@/components/ui/videos/VideoClipLibrary.vue';
import VideoClipPreview from '@/components/ui/videos/VideoClipPreview.vue';
import VideoLibraryProvider from '@/components/hoc/VideoLibraryProvider.vue';
import { VideoModel } from '@/models/video/VideoModel';
import { VideoClipModel } from '@/models/video/VideoClipModel';
import { athleteProfile as athleteProfileStore, notificationStore } from '@/store';
import { videoClipApi } from '@/api/VideoClipApi';
import { BehaviorSubject } from 'rxjs';
import SubscriptionInfoProvider from "@/components/hoc/SubscriptionInfoProvider.vue";
import { VideoUploadDialogVariant } from "@/components/videoStudio/VideoUploadDialog.vue";
import { videoUrlParser, youtubeThumbnailUrl } from "@/helpers";
import { HostedVideoDetail } from "@/../types";
import VideoThumbnail from '@/components/ui/videos/VideoThumbnail.vue';

@Component({
	components: {
		Page,
		AthleteVideoHighlightReel,
		ClipPlayerStudio,
		BAVideoStudio,
		VideoClipPreview,
		VideoClipLibrary,
		VideoLibraryProvider,
		SubscriptionInfoProvider,
		VideoThumbnail,
	}
})

export default class AthleteVideoStudio extends Mixins(MyAthleteMixin, VuetifyMixin, VideoUploadMixin, FormRulesMixin, BAIconsMixin, StringsMixin) {
	VideoUploadDialogVariant = VideoUploadDialogVariant

	videos: VideoModel[] = [];
	videoClips: VideoClipModel[] = [];
	selectedVideoClipObservable: BehaviorSubject<VideoClipModel | null> = new BehaviorSubject<VideoClipModel | null>(null);

	selectedHighlightClipObservable: BehaviorSubject<VideoClipModel | null> = new BehaviorSubject<VideoClipModel | null>(null);

	videoStudioVisible: boolean = false;
	highlightReelPlayerVisible: boolean = false;
	HighlightReelClips(VideoClips: VideoClipModel[]): VideoClipModel[]{
		return VideoClips.filter(clip => clip.inHighlightReel);
	}
	clipPlayerVisible: boolean = false;

	filteredClips: VideoClipModel[] = [];
	updateFilteredClips(clips: VideoClipModel[]): void{
		this.filteredClips = clips;
	}

	$refs: {
		highlightClipPlayer: ClipPlayerStudio,
		videoClipPlayer: ClipPlayerStudio,
	};
	playAll(): void{
		this.highlightReelPlayerVisible = true;
		this.$refs.highlightClipPlayer.playAll();
	}
	playClip(videoClip: VideoClipModel): void{
		this.clipPlayerVisible = true;
		this.selectedVideoClipObservable.next(videoClip);
	}
	videoClipClicked(videoClip: VideoClipModel): void{
		this.playClip(videoClip);
	}
	playHighlightClip(videoClip: VideoClipModel): void{
		this.highlightReelPlayerVisible = true;
		this.selectedHighlightClipObservable.next(videoClip);
	}
	highlightVideoClipClicked(videoClip: VideoClipModel): void{
		this.playHighlightClip(videoClip);
	}

	async deleteClip(clip: VideoClipModel): Promise<void>{
		await videoClipApi.delete(clip).catch((e) => console.error(e));
		await this.persistClips(this.videoClips.filter(v => v.id !== clip.id));
	}
	
	async persistClips(clips: VideoClipModel[]): Promise<void> {
		await athleteProfileStore.patchAthleteProfile({ 
			patch: { id: this.TheAthleteId, clips: clips }, 
			options: {withAcls: false},
		})
	}

	get HasHighlightVideo() {
		return this.IsNotEmptyArray(this.athleteProfile.highlightDetails);
	}
	get HasFullMatchVideo() {
		return this.IsNotEmptyArray(this.athleteProfile.fullmatchDetails);
	}

	hasChanges: boolean = false;
	onAddHighlightVideo() {
		if( !this.HasHighlightVideo ) this.athleteProfile.highlightDetails = [];
		this.athleteProfile.highlightDetails.unshift({videoId:'', label:'', videoUrl:'', thumbnailUrl:''});
		this.hasChanges = true;
	}
	onAddFullMatchVideo() {
		if( !this.HasFullMatchVideo ) this.athleteProfile.fullmatchDetails = [];
		this.athleteProfile.fullmatchDetails.unshift({videoId:'', label:'', videoUrl:'', thumbnailUrl:''});
		this.hasChanges = true;
	}
	onInputHighlight(index: number) {
		const videoId = videoUrlParser(this.athleteProfile.highlightDetails[index].videoUrl);
		if( this.IsEmpty(videoId) ) return;

		this.athleteProfile.highlightDetails[index].videoId = videoId;
		if( this.IsEmpty(this.athleteProfile.highlightDetails[index].label) ) this.athleteProfile.highlightDetails[index].label = videoId;
		this.athleteProfile.highlightDetails[index].thumbnailUrl = youtubeThumbnailUrl(videoId);
		this.hasChanges = true;
	}
	onInputFullMatch(index: number) {
		const videoId = videoUrlParser(this.athleteProfile.fullmatchDetails[index].videoUrl);
		if( this.IsEmpty(videoId) ) return;

		this.athleteProfile.fullmatchDetails[index].videoId = videoId;
		if( this.IsEmpty(this.athleteProfile.fullmatchDetails[index].label) ) this.athleteProfile.fullmatchDetails[index].label = videoId;
		this.athleteProfile.fullmatchDetails[index].thumbnailUrl = youtubeThumbnailUrl(videoId);
		this.hasChanges = true;
	}

	async saveVideoLinks() {
		await athleteProfileStore.patchAthleteProfile({
			patch: {
				id: this.TheAthleteId,
				highlightDetails: this.athleteProfile.highlightDetails,
				fullmatchDetails: this.athleteProfile.fullmatchDetails,
			},
		});
		this.hasChanges = false;
		notificationStore.snackbarChangesSavedSuccess();
	}

	playVideo(video: HostedVideoDetail) {
		if( !this.isValidUrl(video.videoUrl) ) return;
        window.open( video.videoUrl, "_blank" );
    }

	onDeleteHighlight(i: number) {
		this.athleteProfile.highlightDetails.splice(i, 1);
		this.hasChanges = true;
	}
	onDeleteFullMatch(i: number) {
		this.athleteProfile.fullmatchDetails.splice(i, 1);
		this.hasChanges = true;
	}
	
	swapItems(arr: Array<any>, from: number, to: number): Array<any> {
		if( from === to ) return;
		if( from < 0 || to < 0 || from >= arr.length || to >= arr.length ) return;
		const newArray = [...arr];
		const [item] = newArray.splice(from, 1);
		newArray.splice(to, 0, item);
		this.hasChanges = true;
		return newArray;
	}
	onMoveHighlight(from: number, to: number) {
		if( !this.HasHighlightVideo ) return;
		this.athleteProfile.highlightDetails = this.swapItems(this.athleteProfile.highlightDetails, from, to);
	}
	onMoveFullMatch(from: number, to: number) {
		if( !this.HasFullMatchVideo ) return;
		this.athleteProfile.fullmatchDetails = this.swapItems(this.athleteProfile.fullmatchDetails, from, to);
	}

}
