
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AthleteMetric } from '@/../types/enums';
import Page from './Page.vue';
import { VuetifyMixin, AuthMixin, AthleteApiMixin, StringsMixin } from '../mixins';
import {athleteProfile} from '../store';
import AssessmentDataInfoProvider from '../components/hoc/AssessmentDataInfoProvider.vue';
import { formatDateMonthDay } from '../helpers';
import { formatNumber } from '../pipes/formatNumber.pipe';
import { AthleteAssessmentDataModel } from '../models/athlete/AthleteAssessmentDataModel';
import { AthleteProfileModel } from '../models/athlete/AthleteProfileModel';
import { AthleteComparativeDataSummary } from "@/../types/constants/web_client_user.routes";
import AthleteVerifiedAssessmentsCard from "@/components/profile/athlete/AthleteVerifiedAssessmentsCard.vue";
import AthleteVerifiedAssessmentsHistory from "@/components/profile/athlete/AthleteVerifiedAssessmentsHistory.vue"

@Component({
	components:{
		Page,
		AthleteVerifiedAssessmentsCard,
		AthleteVerifiedAssessmentsHistory,
		AssessmentDataInfoProvider,
	},
	async beforeRouteEnter(to, _, next){
		next((vm: AthleteVerifiedAssessmentSummary) => {
			if(vm.athleteId !== null){
				athleteProfile.getAthleteProfile(vm.athleteId);
				athleteProfile.loadVerifiedAssessments({athleteId: vm.athleteId});
			}else{
				athleteProfile.getAthleteProfile(vm.$auth.athleteId);
				athleteProfile.loadVerifiedAssessments({athleteId: vm.$auth.athleteId});
			}
		});
	},
	async beforeRouteUpdate(_, __, next){
		if(this.athleteId !== null){
			athleteProfile.getAthleteProfile(this.athleteId);
			athleteProfile.loadVerifiedAssessments({athleteId: this.athleteId});
		}else{
			athleteProfile.getAthleteProfile(this.$auth.athleteId);
			athleteProfile.loadVerifiedAssessments({athleteId: this.$auth.athleteId});
		}
		next();
	},
})
export default class AthleteVerifiedAssessmentSummary extends Mixins(AuthMixin, VuetifyMixin, AthleteApiMixin, StringsMixin){
	@Prop({ default: null }) athleteId: string | null;

	formatDateMonthDay = formatDateMonthDay;
	formatNumber = formatNumber;

	onViewComparativeData(metric: AthleteMetric) {
		// view the comparative data for the specified metric for this athlete
		this.$router.push({
			name: AthleteComparativeDataSummary,
			params:{
				metric,
				athleteId: this.athleteId,
			}
		});
		// go to the top of the page
		this.$vuetify.goTo(0);
	}

	get Loading(): boolean{
		return athleteProfile.IsLoading || athleteProfile.verifiedAssessmentsLoading || !this.loadedSports;
	}
	get LatestAssessment(): AthleteAssessmentDataModel {
		for( const assessment of this.VerifiedAssessments ) {
			if( this.IsNotEmpty(assessment.acceleration) ||
				this.IsNotEmpty(assessment.speed) ||
				this.IsNotEmpty(assessment.reactiveStrengthIndex) ||
				this.IsNotEmpty(assessment.power) ||
				this.IsNotEmpty(assessment.yoyoIntermittentRecoveryTestDistance)
			) {
				return assessment
			}
		}
		return this.VerifiedAssessments[0];
	}
	get VerifiedAssessments(): AthleteAssessmentDataModel[]{
		return athleteProfile.verifiedAssessments.slice().sort((a,b) => {
			return +b.assessmentDate - +a.assessmentDate;
		});
	}
	get AthleteProfile(): AthleteProfileModel{
		return athleteProfile.athleteProfile;
	}
}
