import { render, staticRenderFns } from "./AthleteComparativeDataPerformanceSummary.vue?vue&type=template&id=f430fdcc&scoped=true&"
import script from "./AthleteComparativeDataPerformanceSummary.vue?vue&type=script&lang=ts&"
export * from "./AthleteComparativeDataPerformanceSummary.vue?vue&type=script&lang=ts&"
import style0 from "./AthleteComparativeDataPerformanceSummary.vue?vue&type=style&index=0&id=f430fdcc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f430fdcc",
  null
  
)

export default component.exports