
import { Component, Mixins } from 'vue-property-decorator';
import { MyAthleteMixin } from '@/mixins';
import RecruitingProfileInfoProvider from '@/components/hoc/RecruitingProfileInfoProvider.vue';
import AthleteScoutingReportsCard from "@/components/profile/athlete/AthleteScoutingReportsCard.vue";
import AthleteScoutingReportsHistory from "@/components/profile/athlete/AthleteScoutingReportsHistory.vue";

@Component({
	components:{
		RecruitingProfileInfoProvider,
		AthleteScoutingReportsCard,
		AthleteScoutingReportsHistory,
	}
})
export default class AthleteScoutingReportsView extends Mixins(MyAthleteMixin){
	mounted() {
		this.loadReports();
	}

	async loadReports() {
		await this.loadMyAthleteProfiles();
		await this.MyAthleteProfile.loadTeams();
	}
}
