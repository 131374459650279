
import { VuetifyMixin } from "@/mixins/VuetifyMixin";
import { Component, Prop, Mixins } from "vue-property-decorator";
import { FormRulesMixin, MyAthleteMixin, StringsMixin, AthleteRoutingMixin, BAIconsMixin } from '@/mixins';
import SubscriptionInfoProvider from '@/components/hoc/SubscriptionInfoProvider.vue';
import { athleteProfile as athleteProfileStore } from '@/store';
import { HostedVideoDetail } from "types";
import { videoUrlParser, youtubeThumbnailUrl } from "@/helpers";
import VideoThumbnail from '@/components/ui/videos/VideoThumbnail.vue';

@Component({
	components: { SubscriptionInfoProvider, VideoThumbnail }
})
export default class ShareProfile extends Mixins(FormRulesMixin, VuetifyMixin, BAIconsMixin, MyAthleteMixin, StringsMixin, AthleteRoutingMixin) {
	@Prop({ type: Boolean }) show;
	@Prop({ type: Boolean }) large;
	@Prop({ type: Boolean }) small;
	@Prop({ type: Boolean }) noshare;
	@Prop({ type: Boolean }) noActivator;
	@Prop({ type: String }) tooltip;
	@Prop({ type: String }) shareButtonText;
	@Prop({ default:[]}) emails: string[];

	clicked: boolean = false;
	message: string = "";
	shareButtonHover: boolean = false;
	showHighlightVideoLinks = false;

	isUnlimitedShares(numSharesAvailable: number | null): boolean {
		return numSharesAvailable === null;
	}
	isSharingReady(numSharesAvailable: number | null): boolean{
		// if numSharesAvailable is null, it indicates unlimited shares
		if( this.isUnlimitedShares(numSharesAvailable) ) return true;
		if( this.IsEmpty(numSharesAvailable) ) return false;
		if( this.emails.length > numSharesAvailable ) return false;
		return true;
	}
	NumberOfSharesText(numSharesAvailable : number | null): string {
		if( this.isUnlimitedShares(numSharesAvailable) ) return 'unlimited';
		return numSharesAvailable.toString();
	}

	// tooltip color is 'success' if shares are available
	ShareButtonTooltipColor(numSharesAvailable: number | null): string {
		if( this.isUnlimitedShares(numSharesAvailable) ) return "success";
		if( this.noshare ) return "error";
		if( this.IsEmpty(numSharesAvailable) ) return "error";
		return "success";
	}	
	ShareButtonToolTipText(numSharesAvailable: number | null, isSubscriptionExpired: boolean): string {
		if( !this.isUnlimitedShares(numSharesAvailable) ) {
			if( isSubscriptionExpired ) return "Your subscription has expired";
			if( this.IsEmpty(numSharesAvailable) ) return "Upgrade your account to share your profile";
			if( this.noshare ) return "Upgrade your subscription to view details";
		}
		if( this.IsEmpty(this.tooltip) ) return "Share your profile with coaches and scouts";
		return this.tooltip;
	}
	ShareButtonColor(numSharesAvailable: number | null, isSubscriptionExpired: boolean): string {
		if( isSubscriptionExpired ) return "error";
		return "primary";
	}
	ShareButtonText(numSharesAvailable: number | null, isSubscriptionExpired: boolean): string {
		if( this.IsNotEmpty(this.shareButtonText) ) return this.shareButtonText;

		if( this.shareButtonHover ) {
			if( this.isUnlimitedShares(numSharesAvailable) ) return "Share Profile";
			if( isSubscriptionExpired ) return "Renew";
			if( this.IsEmpty(numSharesAvailable) ) return "Upgrade Now";
		} else {
			if( isSubscriptionExpired ) return "Expired";
		}
		return "Share Profile";
	}
	ShareButtonIcon(numSharesAvailable: number | null, isSubscriptionExpired: boolean): string {
		if( this.isUnlimitedShares(numSharesAvailable) ) return this.BAIcons.share;
		if( isSubscriptionExpired ) return this.BAIcons.lockWarn;
		if( numSharesAvailable === 0 ) return this.BAIcons.locked;
		return this.BAIcons.share;
	}

	// event: share profile button clicked
	onClickShareProfile(numSharesAvailable: number = 0): void {
		if( this.isUnlimitedShares(numSharesAvailable) ) this.$emit('update:show', true);
		if( numSharesAvailable === 0 ) this.gotoUpgrade();
		if( this.noshare ) this.gotoUpgrade();
		else this.$emit('update:show', true);
	}

	resetForm(): void{
		this.emails = [];
		this.message = "";
	}
	updateEmail(email: string[]): void{
		this.emails = email
	}
	updateMessage(message: string):void{
		this.message = message;
	}

	get NoActivator(): boolean{
		return this.noActivator;
	}
	get InvalidEmailsMessage(): string | undefined{
		if(this.InvalidEmails.length === 0) return undefined;
		return `Invalid emails: ${this.InvalidEmails.join(', ')}`;
	}
	get InvalidEmails(): string[]{
		return this.emails.filter(email => this.rulesOptionalEmail[0](email) !== true);
	}
	get FormValid(): boolean{
		return this.message.length > 0 && this.InvalidEmails.length === 0 && this.emails.length > 0;
	}

	get Clicked(): Record<string, any> {
		return {
			backgroundColor: this.getColor("baColorGreen2"),
			clicked: this.clicked
		};
	}
	async shareProfile(loadPlanInformation:() =>  Promise<void>): Promise<void>{
		this.$emit("submit", {
			emails: this.emails,
			message: this.message
		});
		this.resetForm();
		
		this.$emit('update:show', false);
		await loadPlanInformation();
	}

	editHighlightDetails = false;
	originalHighlightDetails: HostedVideoDetail[];
	originalFullMatchDetails: HostedVideoDetail[];
	onCancelEditHighlightDetails() {
		Object.assign(this.athleteProfile.highlightDetails, this.originalHighlightDetails);
		Object.assign(this.athleteProfile.fullmatchDetails, this.originalFullMatchDetails);
		this.editHighlightDetails = false;
	}
	async onSaveHighlightDetails() {
		await athleteProfileStore.patchAthleteProfile({
			patch: {
				id: this.athleteProfile.id, 
				highlightDetails: this.athleteProfile.highlightDetails,
				fullmatchDetails: this.athleteProfile.fullmatchDetails,
			}});
		this.editHighlightDetails = false;
	}
	onEditHighlightDetails() {
		this.originalHighlightDetails = [];
		this.originalFullMatchDetails = [];
		Object.assign(this.originalHighlightDetails, this.athleteProfile.highlightDetails);
		Object.assign(this.originalFullMatchDetails, this.athleteProfile.fullmatchDetails);
		this.editHighlightDetails = true;
	}
	onWatchVideo(url: string) {
		window.open(url, '_blank').focus();
	}
	onAddHighlightVideo() {
		if( !this.athleteProfile.highlightDetails ) this.athleteProfile.highlightDetails = [];
		this.athleteProfile.highlightDetails.unshift({videoId:'', label:'', videoUrl:'', thumbnailUrl:''});
	}
	onAddFullMatchVideo() {
		if( !this.athleteProfile.fullmatchDetails ) this.athleteProfile.fullmatchDetails = [];
		this.athleteProfile.fullmatchDetails.unshift({videoId:'', label:'', videoUrl:'', thumbnailUrl:''});
	}
	onInputHighlight(index: number) {
		const videoId = videoUrlParser(this.athleteProfile.highlightDetails[index].videoUrl);
		if( this.IsEmpty(videoId) ) return;

		this.athleteProfile.highlightDetails[index].videoId = videoId;
		if( this.IsEmpty(this.athleteProfile.highlightDetails[index].label) ) this.athleteProfile.highlightDetails[index].label = videoId;
		this.athleteProfile.highlightDetails[index].thumbnailUrl = youtubeThumbnailUrl(videoId);
	}
	onInputFullMatch(index: number) {
		const videoId = videoUrlParser(this.athleteProfile.fullmatchDetails[index].videoUrl);
		if( this.IsEmpty(videoId) ) return;

		this.athleteProfile.fullmatchDetails[index].videoId = videoId;
		if( this.IsEmpty(this.athleteProfile.fullmatchDetails[index].label) ) this.athleteProfile.fullmatchDetails[index].label = videoId;
		this.athleteProfile.fullmatchDetails[index].thumbnailUrl = youtubeThumbnailUrl(videoId);
	}
	playVideo(video: HostedVideoDetail) {
		if( !this.isValidUrl(video.videoUrl) ) return;
        window.open( video.videoUrl, "_blank" );
    }
}
