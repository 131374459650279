
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin, AppHostnameMixin, StringsMixin, AthleteRoutingMixin, AthleteProfileMixin, AthleteApiMixin, CoachRoutingMixin, PaginatedTableMixin, ScoutingReportMixin } from '@/mixins';
import { DataTableHeader } from 'vuetify';
import { RepositoryQuery, QueryOptions, PaginatedResponse } from '@/../types/interfaces';
import { ScoutingReportModel, ScoutingReportModelEx } from '@/models/scoutingReport/ScoutingReportModel';
import { AthleteProfileModel, CoachProfileModel } from '@/models';
import ScoutingReportBrief from '@/components/profile/athlete/ScoutingReportBrief.vue';
import { athleteApi } from '@/api/AthleteApi';
import { scoutingReportApi } from '@/api/ScoutingReportApi';

@Component({
	components: {
		ScoutingReportBrief,
	}
})
export default class AthleteScoutingReportsHistory extends Mixins(AppHostnameMixin, BAIconsMixin, StringsMixin, VuetifyMixin, PaginatedTableMixin, ScoutingReportMixin, AthleteProfileMixin, AthleteRoutingMixin, CoachRoutingMixin, AthleteApiMixin){
	@Prop() scoutingReport: ScoutingReportModel;
	@Prop({ type: Boolean }) showIncomplete;
	scoutingReportsDetails: Array<ScoutingReportModelEx> = [];

	mounted() {
		this.tableOptions.sortBy = ['submitted','createdAt'];
		this.tableOptions.sortDesc = [true, true];
		this.localForagePersistFields = [['search', ''],['tableOptions.page', 1],['tableOptions.itemsPerPage', 25]];
	}

	athletes: Array<AthleteProfileModel> = [];
	coaches: Array<CoachProfileModel> = [];

	isScoutingReportsLoading: boolean = false;
	isScoutingReportsLoaded: boolean = false;
	async loadDetails(scoutingReports: Array<ScoutingReportModel>): Promise<Array<ScoutingReportModelEx>> {
		return Promise.all( scoutingReports.map(report => ScoutingReportModelEx.fromScoutingReport(report, this.athletes, this.coaches)) );
	}
	async loadTable() {
		this.isScoutingReportsLoading = true;
		this.isScoutingReportsLoaded = false;
		const query: RepositoryQuery<ScoutingReportModel> = this.TableQuery<ScoutingReportModel>(this.search, ['gameDetails.coachId', 'notes', 'scoutingReport.gameDetails.teamName']);
		query.$match = {...query.$match, ...{ 'athleteId': this.AthleteId }};
		if( !this.showIncomplete ) query.$match = {...query.$match, ...{ 'submitted': true }};
		const options: QueryOptions = this.TableQueryOptions;
		const response: PaginatedResponse<ScoutingReportModel> = await scoutingReportApi.queryAll(query, options);
		this.scoutingReportsDetails = await this.loadDetails(response.docs);
		this.dataItemsCount = response.total;
		this.isScoutingReportsLoaded = true;
		this.isScoutingReportsLoading = false;
	}

	get TableLoading(): boolean {
		return this.isScoutingReportsLoading;
	}
	get PageLoading(): boolean {
		return this.TableLoading || this.isLoadingAthleteProfile;
	}

	get TableHeaders(): Array<DataTableHeader<any>> {
		let headers: Array<DataTableHeader<any>> = [
			{ text: 'Date', value: 'createdAt', sortable: false },
			{ text: 'Coach', value: 'coach', sortable: false },
			{ text: 'Team', value: 'team', sortable: false },
			{ text: 'Location', value: 'location', sortable: false },
			{ text: 'Score', value: 'performance', sortable: false, width:'90', align:'center' },
			{ text: 'Potential', value: 'potential', sortable: false, width:'90', align:'center' },
		];
		if( this.IsLargeScreen ) {
			headers.push({text: '', value: 'actions', sortable: false });
			headers.push({text: '', value: 'data-table-expand', sortable: false});
		}
		return headers;
	}
	
	updatingFeatured: boolean = false;
	get HasScoutingReports(): boolean {
		if( this.IsNotEmpty(this.scoutingReport) ) return true;
		return this.IsNotEmpty(this.scoutingReportsDetails);
	}

	get FeaturedScoutingReport(): ScoutingReportModel {
		if( this.IsNotEmpty(this.scoutingReport) ) return this.scoutingReport;
		if( !this.HasScoutingReports ) return undefined;
		this.scoutingReport = this.scoutingReportsDetails.find(sr => sr.id === this.athleteProfile.featuredScoutingReport);
		if( this.IsNotEmpty(this.scoutingReport) ) return this.scoutingReport;
		return this.LatestScoutingReport;
	}
	get LatestScoutingReport(): ScoutingReportModel {
		if( this.IsNotEmpty(this.scoutingReport) ) return this.scoutingReport;
		if( !this.HasScoutingReports ) return undefined;
		this.scoutingReport = this.scoutingReportsDetails.slice(-1)[0];
		return this.scoutingReport;
	}
	get FeaturedScoutingReportDate(): string {
		const scoutingReport = this.FeaturedScoutingReport;
		if( !scoutingReport ) return 'No Scouting Reports available';
		return this.formatDatePretty(scoutingReport.createdAt);		
	}
	
	isFeatured(report: ScoutingReportModel): boolean {
		return( this.athleteProfile.featuredScoutingReport === report.id );
	}
	async onSetFeatured(report: ScoutingReportModel) {
        this.updatingFeatured = true;
        this.athleteProfile.featuredScoutingReport = report.id;
        await athleteApi.save(this.athleteProfile);
        this.updatingFeatured = false;
	}

	IsBlurred(isFreePlan: boolean): boolean {
		if( this.CoachAppActive ) return this.public;
		return this.public || isFreePlan;
	}

	goToScoutingReportView(reportId: string) {
		if( this.CoachAppActive || this.AdminAppActive ) {
			this.gotoCoachViewScoutingReport(reportId);
		} else {
			this.goToScoutingReport(reportId);
		}
	}
}
